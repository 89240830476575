import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../utils/Api';
import { Toaster, toast } from 'sonner';

const FollowUp = ({ selectedAppointmentId }) => {
  const [followUps, setFollowUps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatingFollowUp, setUpdatingFollowUp] = useState(null);

  const today = new Date();
  const minDate = new Date(today);
  const maxDate = new Date(today);
  minDate.setDate(today.getDate() + 1);
  maxDate.setDate(today.getDate() + 3);

  const formatDate = (date) => date.toISOString().split('T')[0];

  // Fetch follow-ups from API
  const fetchFollowUps = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/doctors/follow-ups/appointment/${selectedAppointmentId}`);
      setFollowUps(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching follow-ups:', error);
      toast.error('Failed to fetch follow-ups.');
    } finally {
      setLoading(false);
    }
  };

  // Create or update follow-up
  const handleFollowUpSubmit = async (values, { resetForm }) => {
    try {
      const endpoint = updatingFollowUp
        ? `/doctors/follow-ups/${updatingFollowUp.id}`
        : '/doctors/follow-ups';
      const method = updatingFollowUp ? 'put' : 'post';
      const response = await api[method](endpoint, {
        ...values,
        appointmentId: selectedAppointmentId,
      });

      const successMessage = response.data?.message || 'Follow-up saved successfully!';
      toast.success(successMessage);

      fetchFollowUps();
      resetForm();
      setUpdatingFollowUp(null);
    } catch (error) {
      if (error.response?.data?.errors) {
        error.response.data.errors.forEach((err) => toast.error(err.msg));
      } else {
        toast.error('Failed to save follow-up. Please try again.');
      }
    }
  };

   // Delete follow-up
   const deleteFollowUp = (id) => {
    if (!id) {
      toast.error('Invalid follow-up ID. Unable to delete.');
      return;
    }

    toast(
      (t) => (
        <div>
          <p>Are you sure you want to delete this follow-up?</p>
          <div className="flex space-x-2 mt-2">
            <button
              onClick={async () => {
                toast.dismiss(t);
                try {
                  const response = await api.delete(`/doctors/follow-ups/${id}`);
                  toast.success('Follow-up deleted successfully!');
                  fetchFollowUps();
                } catch (error) {
                  // Show a specific error message based on the response
                  if (error.response) {
                    const serverMessage = error.response.data?.message || 'Failed to delete follow-up.';
                    toast.error(`Error: ${serverMessage}`);
                  } else {
                    toast.error('Network error. Please try again.');
                  }
                }
              }}
              className="bg-red-500 hover:bg-red-400 text-white py-1 px-3 rounded"
            >
              Yes, Delete
            </button>
            <button
              onClick={() => toast.dismiss(t)}
              className="bg-gray-300 hover:bg-gray-200 text-black py-1 px-3 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      ),
      { duration: Infinity }
    );
  };

  useEffect(() => {
    if (selectedAppointmentId) fetchFollowUps();
  }, [selectedAppointmentId]);

  return (
    <div className="px-6 space-y-6">
      <Toaster richColors />

      {/* Follow-Up Form */}
      <Formik
        initialValues={{
          followUpDate: '',
          followUpTime: '',
          followUpType: '',
          followUpReason: '',
        }}
        validationSchema={Yup.object().shape({
          followUpDate: Yup.string().required('Follow-up date is required'),
          followUpTime: Yup.string().required('Follow-up time is required'),
          followUpType: Yup.string().required('Follow-up type is required'),
          followUpReason: Yup.string().min(5, 'Reason must be at least 5 characters').required('Follow-up reason is required'),
        })}
        onSubmit={handleFollowUpSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="bg-white p-4 rounded shadow-md space-y-4">
            <h2 className="text-lg font-semibold text-gray-700">
              {updatingFollowUp ? 'Update Follow-Up' : 'Create Follow-Up'}
            </h2>

            <div>
              <label htmlFor="followUpDate" className="block text-sm text-gray-600">
                Follow-Up Date
              </label>
              <Field
                name="followUpDate"
                type="date"
                className="w-full p-2 border rounded focus:ring focus:ring-blue-200"
                min={formatDate(minDate)}
                max={formatDate(maxDate)}
              />
              <ErrorMessage name="followUpDate" component="div" className="text-red-500 text-sm" />
            </div>

            <div>
              <label htmlFor="followUpTime" className="block text-sm text-gray-600">
                Follow-Up Time
              </label>
              <Field name="followUpTime" type="time" className="w-full p-2 border rounded focus:ring focus:ring-blue-200" />
              <ErrorMessage name="followUpTime" component="div" className="text-red-500 text-sm" />
            </div>

            <div>
              <label htmlFor="followUpType" className="block text-sm text-gray-600">
                Follow-Up Type
              </label>
              <Field
                name="followUpType"
                as="select"
                className="w-full p-2 border rounded focus:ring focus:ring-blue-200"
              >
                <option value="">Select Type</option>
                <option value="online_consultation">Online Consultation</option>
                <option value="in_person">In-Person</option>
              </Field>
              <ErrorMessage name="followUpType" component="div" className="text-red-500 text-sm" />
            </div>

            <div>
              <label htmlFor="followUpReason" className="block text-sm text-gray-600">
                Follow-Up Reason
              </label>
              <Field
                name="followUpReason"
                as="textarea"
                rows="3"
                className="w-full p-2 border rounded focus:ring focus:ring-blue-200"
              />
              <ErrorMessage name="followUpReason" component="div" className="text-red-500 text-sm" />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="bg-blue-500 hover:bg-blue-600 text-white w-full py-2 rounded"
            >
              {isSubmitting ? 'Saving...' : updatingFollowUp ? 'Update Follow-Up' : 'Create Follow-Up'}
            </button>
          </Form>
        )}
      </Formik>

      {/* Follow-Ups List */}
      <div className="bg-white p-4 rounded shadow-md max-h-[10rem] overflow-y-auto">
        <h2 className="text-lg font-semibold text-gray-700">Existing Follow-Ups</h2>
        {loading ? (
          <p>Loading...</p>
        ) : followUps.length === 0 ? (
          <p>No follow-ups available.</p>
        ) : (
          <ul className="divide-y divide-gray-200">
            {followUps.map((followUp) => (
              <li key={followUp.followUpId} className="py-3 flex justify-between items-center">
                <div>
                  <p className="text-sm text-gray-600">
                    <strong>Follow-Up ID:</strong> {followUp.followUpId}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Date:</strong> {followUp.followUpDate}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Time:</strong> {followUp.followUpTime}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Type:</strong> {followUp.followUpType.replace('_', ' ')}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Status:</strong> {followUp.followUpStatus}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Reason:</strong> {followUp.reason}
                  </p>
                  <p className="text-sm text-gray-600">
                    <strong>Created At:</strong> {new Date(followUp.createdAt).toLocaleString()}
                  </p>
                </div>
                <div className="flex space-x-2">
                  <button onClick={() => setUpdatingFollowUp(followUp)} className="text-blue-500 hover:text-blue-700">
                    Edit
                  </button>
                  <button onClick={() => deleteFollowUp(followUp.followUpId)} className="text-red-500 hover:text-red-700">
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>


    </div>
  );
};

export default FollowUp;
