import React, { useState, useEffect } from 'react';
import { Formik, Field, FieldArray, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../utils/Api';
import { Toaster, toast } from 'sonner';

const Prescriptions = ({ selectedAppointmentId,  appointments }) => {
  const [prescriptions, setPrescriptions] = useState([]);
  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);

  // Validation schema for the form fields
  const validationSchema = Yup.object().shape({
    diagnosis: Yup.string().required("Diagnosis is required"),
    prescriptions: Yup.array().of(
      Yup.object({
        medicineName: Yup.string().required("Medicine Name is required"),
        strength: Yup.string().required("Strength is required"),
        dosage: Yup.string().required("Dosage is required"),
        treatmentDays: Yup.number().required("Treatment days is required")
          .min(1, "Treatment days must be at least 1")
      })
    )
  });

  // Fetch prescription data by appointment ID
  const fetchPrescriptions = async () => {
    try {
      const response = await api.get(`/doctors/prescriptions/appointment/${selectedAppointmentId}`);

      const { status, statusCode, data } = response.data;

      if (statusCode === 200 && data) {
        // Set prescriptions state with the fetched data directly
        setPrescriptions(data);

        // Optionally, display success toast message
        toast.success('Prescriptions fetched successfully');
      } else {
        // Handle any error from backend response
        toast.error('An error occurred while fetching prescriptions');
      }
    } catch (error) {
      // Handle errors like network or server issues
      console.error('Fetch error:', error);
      toast.error('Error fetching prescriptions');
    }
  };

   // Fetch prescription details by ID
   const fetchPrescriptionDetails = async (prescriptionId) => {
    setLoadingDetails(true);
    try {
      const response = await api.get(`/doctors/prescriptions/${prescriptionId}`);
      const { data } = response.data;

      setSelectedPrescription(data);
      setIsPopupOpen(true);
    } catch (error) {
      console.error('Error fetching prescription details:', error);
      toast.error('Error fetching prescription details');
    } finally {
      setLoadingDetails(false);
    }
  };

  // Form submission handler
  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    console.log('Submitted Values:', values); // Debug: Log form values before submission

    try {
      // Prepare payload
      const payload = {
        appointmentId: selectedAppointmentId,
        diagnosis: values.diagnosis,
        medicines: values.prescriptions.map((prescription) => ({
          medicineName: prescription.medicineName,
          strength: prescription.strength,
          dosage: prescription.dosage,
          treatmentDuration: prescription.treatmentDays + ' days',
          comment: prescription.comment || '', // Optional field
        })),
        comment: values.comment,
      };

      // Make API call
      const response = await api.post('/doctors/prescriptions', payload);

      const { message, statusCode, data } = response.data;

      if (statusCode === 201) {
        toast.success(message || 'Prescription added successfully');
        setPrescriptions([...prescriptions, ...(data?.medicines || [])]); // Append new medicines
        resetForm();
      } else {
        toast.error(message || 'An error occurred while adding the prescription');
      }
    } catch (error) {
      console.error('Error submitting prescription:', error);

      // Handle API error response
      if (error.response && error.response.data) {
        const apiErrors = error.response.data.errors || [];
        if (apiErrors.length > 0) {
          apiErrors.forEach((err) => toast.error(err.msg)); // Display individual error messages
        } else {
          toast.error(error.response.data.message || 'An error occurred');
        }
        console.log('Error Response:', error.response.data); // Debug: Log error response
      } else {
        // Handle unexpected errors
        toast.error('An unexpected error occurred');

      }
    } finally {
      setLoading(false);
    }
  };


  // Fetch prescriptions when selectedAppointmentId changes
  useEffect(() => {
    if (selectedAppointmentId) {
      fetchPrescriptions(selectedAppointmentId);
    }
  }, [selectedAppointmentId]);

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedPrescription(null);
  };

  return (
    <div className="flex p-4 space-x-4">
      {/* Prescription Form */}
      <div className="w-full">
        <Formik
          initialValues={{
            diagnosis: '',
            prescriptions: [{
              medicineName: '',
              strength: '',
              dosage: '',
              treatmentDays: ''
            }],
            comment: ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched }) => (
            <Form className="w-full">
              <h1 className="py-3 text-sm text-gray-400">Add Prescription</h1>

              {/* Diagnosis Field */}
              <div className="mb-4">
                <Field as="textarea" name="diagnosis" className="p-2 border border-gray-300 rounded w-full mb-2" placeholder="Diagnosis" />
                <ErrorMessage name="diagnosis" component="div" className="text-red-500 text-xs" />
              </div>

              {/* Prescription Fields */}
              <FieldArray
                name="prescriptions"
                render={arrayHelpers => (
                  <div className="sm:max-h-[22rem] overflow-y-auto">
                    {values.prescriptions.map((_, index) => (
                      <div key={index} className="w-full flex flex-col mb-4 gap-2">
                        <div>
                          {/* Medicine Name */}
                          <div className='flex flex-col w-full'>
                            <Field name={`prescriptions[${index}].medicineName`} className="p-2 border border-gray-300 rounded w-full mb-2" placeholder="Medicine Name" />
                            <ErrorMessage name={`prescriptions[${index}].medicineName`} component="div" className="text-red-500 text-[0.60rem]" />
                          </div>

                          {/* Strength */}
                          <div className='flex flex-col w-full'>
                            <Field name={`prescriptions[${index}].strength`} className="p-2 border border-gray-300 rounded w-full mb-2" placeholder="Strength" />
                            <ErrorMessage name={`prescriptions[${index}].strength`} component="div" className="text-red-500 text-[0.60rem]" />
                          </div>

                          {/* Dosage */}
                          <div className='flex flex-col w-full'>
                            <Field name={`prescriptions[${index}].dosage`} className="p-2 border border-gray-300 rounded w-full mb-2" placeholder="Dosage" />
                            <ErrorMessage name={`prescriptions[${index}].dosage`} component="div" className="text-red-500 text-[0.60rem]" />
                          </div>

                          {/* Treatment Days */}
                          <div className='flex flex-col w-full'>
                            <Field name={`prescriptions[${index}].treatmentDays`} className="p-2 border border-gray-300 rounded w-full mb-2" placeholder="Treatment Days" />
                            <ErrorMessage name={`prescriptions[${index}].treatmentDays`} component="div" className="text-red-500 text-[0.60rem]" />
                          </div>
                        </div>

                        {/* Delete Prescription Button */}
                        {index > 0 && (
                          <div className="flex w-full justify-center">
                            <button type="button" onClick={() => arrayHelpers.remove(index)} className="text-red-500 text-sm font-bold px-4 mb-1">Delete Prescription</button>
                          </div>
                        )}
                      </div>
                    ))}
                    <button type="button" onClick={() => arrayHelpers.push({ medicineName: '', strength: '', dosage: '', treatmentDays: '' })} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 ml-2">
                      Add more Prescription
                    </button>
                  </div>
                )}
              />

              {/* Comment Field */}
              <div className="my-4">
                <Field as="textarea" name="comment" className="p-2 border border-gray-300 rounded mb-2 w-full" placeholder="Comment" />
                <ErrorMessage name="comment" component="div" className="text-red-500 text-xs" />
              </div>

              {/* Submit Button */}
              <button type="submit" className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={loading}>
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </Form>
          )}
        </Formik>

        <Toaster richColors />
      </div>

      {/* View Prescriptions */}
      <div className="w-full">
        <h2 className="text-lg font-semibold mb-4">View Prescriptions</h2>
        <ul className="sm:max-h-[30rem] overflow-y-auto">
          {prescriptions.map((prescription) => (
            <li
              key={prescription.prescrtiptionId}
              className="mb-2 p-2 border rounded cursor-pointer"
              onClick={() => fetchPrescriptionDetails(prescription.prescrtiptionId)}
            >
              <p>Prescription ID: {prescription.prescrtiptionId}</p>
              <p>created: {prescription.createdAt}</p>
              <p>updated: {prescription.updatedAt}</p>
            </li>
          ))}
        </ul>
      </div>

      {/* Popup */}
      {isPopupOpen && selectedPrescription && (
  <div className="fixed lg:pl-72 inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <div className="bg-white p-6 rounded shadow-lg max-h-[50rem] max-w-[50rem] overflow-y-auto">
      <h2 className="text-xl font-bold mb-4">Prescription Details</h2>
      <p><strong>Prescription ID:</strong> {selectedPrescription.prescriptionId}</p>
      <p><strong>Appointment ID:</strong> {selectedPrescription.appointmentId}</p>
      <p className='mt-4'><strong>Diagnosis:</strong> {selectedPrescription.diagnosis}</p>
      <p className='mb-4'><strong>Comment:</strong> {selectedPrescription.comment}</p>
      <p><strong>Created At:</strong> {new Date(selectedPrescription.createdAt).toLocaleDateString()}</p>
      <p><strong>Updated At:</strong> {new Date(selectedPrescription.updatedAt).toLocaleDateString()}</p>

      <h3 className="text-lg font-semibold mt-4">Medicines</h3>
      <ul className="list-disc ml-5">
        {selectedPrescription.medicines.map((medicine, index) => (
          <li key={index} className="mb-2">
            <p><strong>Medicine Name:</strong> {medicine.medicineName}</p>
            <p><strong>Strength:</strong> {medicine.strength}</p>
            <p><strong>Dosage:</strong> {medicine.dosage}</p>
            <p><strong>Treatment Duration:</strong> {medicine.treatmentDuration}</p>
          </li>
        ))}
      </ul>

      <button
        className="bg-red-500 text-white hover:bg-red-400 w-full rounded py-2 mt-4"
        onClick={closePopup}
      >
        Close
      </button>
    </div>
  </div>
)}

    </div>
  );
};

export default Prescriptions;
