import React from 'react';
import { Tooltip } from 'flowbite-react';

const AppointmentsTable = ({ appointments, loading, error, handleAction, startMeeting }) => {
  const statusColorMap = {
    'pending': 'text-yellow-500',
    'confirmed': 'text-green-500',
    'completed': 'text-blue-500',
    'postponed': 'text-orange-500',
    'cancelled': 'text-red-500',
    'started': 'text-green-700',
  };

  return (
    <div className="relative  sm:rounded-lg flex w-full items-center overflow-x-auto">
      {/* Error State - Handle error display elsewhere, like in the parent */}
      {error && (
        <div className="text-center py-8">
          <p className="text-red-500">{error}</p>
        </div>
      )}
      {/* Loading State */}
      {loading ? (
        <div className="w-full py-8">
          <p className="text-gray-400 text-center">Loading appointments...</p>
        </div>
      ) : appointments.length > 0 ? (
        <table className="w-full text-sm text-left max-w-[100rem] overflow-x-auto">
          <thead className="text-xs text-center py-3 px-6 text-white uppercase bg-blue-700">
            <tr>
              <th scope="col" className="py-4 px-6">ID</th>
              <th scope="col" className="py-4 px-6">Username</th>
              <th scope="col" className="py-4 px-6">Patient Name</th>
              <th scope="col" className="py-4 px-6">Symptoms</th>
              <th scope="col" className="py-4 px-6">Mobile Number</th>
              <th scope="col" className="py-4 px-6">Payment Method</th>
              <th scope="col" className="py-4 px-6">Appointment Date</th>
              <th scope="col" className="py-4 px-6">Time</th>
              <th scope="col" className="py-4 px-6">Type</th>
              <th scope="col" className="py-4 px-6">Status</th>
              <th scope="col" className="py-4 px-6">Actions</th>
              <th scope="col" className="py-4 px-6">Meeting</th>
            </tr>
          </thead>
          <tbody>
            {appointments.map((appointment, index) => (
              <tr key={appointment.appointmentId} className={index % 2 === 0 ? 'bg-blue-50' : 'bg-white'}>
                <td className="py-4 px-6">{appointment.appointmentId}</td>
                <td className="py-4 px-3">{appointment.username}</td>
                <td className="py-4 px-3">{appointment.patientNameOnPrescription}</td>
                <td className="py-4 px-3">{appointment.patientSymptoms}</td>
                <td className="py-4 px-3">{appointment.patientMobileNumber}</td>
                <td className="py-4 px-3">{appointment.paymentMethod}</td>
                <td className="py-4 px-3">{new Date(appointment.appointmentDate).toLocaleDateString()}</td>
                <td className="py-4 px-3">{appointment.appointmentTime}</td>
                <td className="py-4 px-3">{appointment.appointmentType}</td>
                <td className="py-4 px-3">
                  <span className={statusColorMap[appointment.appointmentStatus] || 'text-gray-500'}>
                    {appointment.appointmentStatus}
                  </span>
                </td>
                <td className="py-4 px-6">
                  <button
                    className="font-medium text-blue-600 hover:underline"
                    onClick={() => handleAction(appointment.appointmentId)}
                  >
                    Actions
                  </button>
                </td>
                <td className="py-4 px-6">
                  <div className="flex">
                    {appointment.appointmentStatus === 'started' && (
                      <Tooltip content="Start meeting" placement="top">
                        <a
                          href={appointment.meetingStartUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => startMeeting(appointment.appointmentId)}
                          className="ml-2 cursor-pointer"
                        >
                          <svg
                            className="w-5 h-5 text-green-500"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10c0 1.1.9 2 2 2h8a2 2 0 0 0 2-2V7Zm2 9.4 4.7 1.5A1 1 0 0 0 22 17V7a1 1 0 0 0-1.3-1L16 7.7v8.8Z"
                            />
                          </svg>
                        </a>
                      </Tooltip>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center py-8">
          <p className="text-gray-500">No appointments found.</p>
        </div>
      )}
    </div>
  );
};

export default AppointmentsTable;
