import React, { useEffect, useState } from 'react';
import { api } from '../../utils/Api';
import { Toaster, toast } from 'sonner';

const Wallet = () => {
  const [currentBalance, setCurrentBalance] = useState(''); // Initial balance
  const [showPinUpdatePopup, setShowPinUpdatePopup] = useState(false); // State to control pin update popup visibility
  const [pinUpdateFormData, setPinUpdateFormData] = useState({
    currentPin: '',
    newPin: '',
    confirmNewPin: ''
  });

  const [withdrawalFormData, setWithdrawalFormData] = useState({
    pin: '',
    amount: '',
    paymentMethod: '',
    mobileMoneyNumber: '',
    accountName: '',
    accountNumber: ''
  });

  const [showWithdrawalPopup, setShowWithdrawalPopup] = useState(false); // State to control popup visibility
  const [walletData, setWalletData] = useState([
    { id: 1, date: '2024-02-20', time: '10:00 AM', amount: 100, balance: 500, method: 'Bank Transfer' },
    { id: 2, date: '2024-02-19', time: '11:30 AM', amount: 50, balance: 450, method: 'Orange Money' },
    // Add more dummy data as needed
  ]);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await api('/doctors/wallets');
        setCurrentBalance(response.data.data);
        toast.success('Balance fetched successfully!');
      } catch (error) {
        toast.error('Error fetching balance. Please try again.');
        console.error('Error fetching balance:', error);
      }
    };
    fetchBalance();
  }, []);

  // Handle Withdrawal Form Submission
  const handleWithdrawalSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post('/doctors/wallets/withdrawal', withdrawalFormData);
      toast.success('Withdrawal successful!');
      // Reset form fields
      setWithdrawalFormData({
        pin: '',
        amount: '',
        paymentMethod: '',
        mobileMoneyNumber: '',
        accountName: '',
        accountNumber: ''
      });
      setShowWithdrawalPopup(false); // Close the popup
    } catch (error) {
      if (error.response?.data?.errors) {
        const errorMessage = error.response.data.errors.map((err) => err.msg).join(' ');
        toast.error(`Withdrawal failed: ${errorMessage}`);
      } else {
        toast.error(`Withdrawal failed: ${error.message}`);
      }
      console.error('Withdrawal error:', error);
    }
  };

  // Handle PIN Update Form Submission
  const handlePinUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.patch('/doctors/wallets', pinUpdateFormData);
      toast.success('PIN updated successfully!');
      // Reset form fields
      setPinUpdateFormData({
        currentPin: '',
        newPin: '',
        confirmNewPin: ''
      });
      setShowPinUpdatePopup(false); // Close the popup
    } catch (error) {
      if (error.response?.data?.errors) {
        const errorMessage = error.response.data.errors.map((err) => err.msg).join(' ');
        toast.error(`PIN update failed: ${errorMessage}`);
      } else {
        toast.error(`PIN update failed: ${error.message}`);
      }
      console.error('PIN update error:', error);
    }
  };

  return (
    <div className="p-4 w-full max-w-7xl bg-gray-50 rounded">
      <Toaster richColors />

      {/* Current Balance Section */}
      <div className="flex flex-row justify-center items-center mb-4 text-white sm:text-sm text-xs font-Poppins gap-4">
        <div className='flex flex-col py-4 px-10 justify-center items-center rounded shadow bg-gradient-to-br from-yellow-400 via-yellow-600 to-yellow-800'>
          <p>Current Balance: </p>
          <span className='font-bold sm:text-lg text-xs'>SLL {currentBalance.balance}</span>
        </div>
      </div>

      {/* PIN Update Popup */}
      {showPinUpdatePopup && (
        <div className="fixed inset-0 flex items-center justify-center lg:pl-72 bg-gray-800 bg-opacity-50 z-0">
          <div className="bg-white p-4 rounded shadow-md sm:w-[30rem]">
            <h2 className="text-lg text-gray-500 font-semibold font-Poppins mb-2">Update PIN</h2>
            <p className="text-gray-500 text-sm my-2">
              Use the default PIN as current PIN if you have not changed it. If changed, enter your updated PIN as the current PIN.
            </p>
            <form onSubmit={handlePinUpdateSubmit} className='flex flex-col'>
              <input
                type="number"
                placeholder="Current PIN"
                value={pinUpdateFormData.currentPin}
                onChange={(e) => {
                  const inputValue = e.target.value.slice(0, 4); // Limit input to 4 characters
                  setPinUpdateFormData({ ...pinUpdateFormData, currentPin: inputValue });
                }}
                className="mb-2 p-2 border rounded"
                required
              />
              <input
                type="number"
                placeholder="New PIN"
                value={pinUpdateFormData.newPin}
                onChange={(e) => {
                  const inputValue = e.target.value.slice(0, 4); // Limit input to 4 characters
                  setPinUpdateFormData({ ...pinUpdateFormData, newPin: inputValue });
                }}
                className="mb-2 p-2 border rounded"
                required
              />
              <input
                type="number"
                placeholder="Confirm New PIN"
                value={pinUpdateFormData.confirmNewPin}
                onChange={(e) => {
                  const inputValue = e.target.value.slice(0, 4); // Limit input to 4 characters
                  setPinUpdateFormData({ ...pinUpdateFormData, confirmNewPin: inputValue });
                }}
                className="mb-2 p-2 border rounded"
                required
              />
              <p className="text-gray-500 text-sm my-2">
                Forgotten PIN? <span className='text-blue-500 font-semibold'><a href="#">Recover</a></span>
              </p>
              <button type="submit" className="bg-green-300 hover:bg-green-500 border border-green-500 text-white font-semibold py-2 px-4 rounded">Update PIN</button>
              <button onClick={() => setShowPinUpdatePopup(false)} className="ml-2 text-red-600 mt-2">Cancel</button>
            </form>
          </div>
        </div>
      )}

      {/* Withdrawal Form Popup */}
      {showWithdrawalPopup && (
        <div className="fixed inset-0 flex items-center justify-center lg:pl-72 bg-gray-800 bg-opacity-50 z-0">
          <div className="bg-white p-4 rounded shadow-md sm:w-[30rem]">
            <h2 className="text-lg text-gray-600 font-semibold font-Poppins mb-2">Withdrawal Form</h2>
            <p className="text-gray-500 mb-2">10% of the withdrawal amount goes to Kenecare.</p>
            <p className="text-gray-500 mb-2">
              Your default PIN is 1234. <span className='text-blue-500'>
                <button onClick={() => {
                  setShowPinUpdatePopup(true);
                  setShowWithdrawalPopup(false);
                }}>
                  Update PIN
                </button>
              </span>
            </p>
            <form onSubmit={handleWithdrawalSubmit} className='flex flex-col'>
              <input
                type="number"
                placeholder="PIN"
                value={withdrawalFormData.pin}
                onChange={(e) => {
                  const inputValue = e.target.value.slice(0, 4); // Limit input to 4 characters
                  setWithdrawalFormData({ ...withdrawalFormData, pin: inputValue });
                }}
                className="mb-2 p-2 border rounded"
                required
              />
              <input
                type="number"
                placeholder="Amount to withdraw"
                value={withdrawalFormData.amount}
                onChange={(e) => setWithdrawalFormData({ ...withdrawalFormData, amount: e.target.value })}
                className="mb-2 p-2 border rounded"
                required
              />
              <select
                value={withdrawalFormData.paymentMethod}
                onChange={(e) => setWithdrawalFormData({ ...withdrawalFormData, paymentMethod: e.target.value })}
                className="mb-2 p-2 border rounded"
                required
              >
                <option value="">Select Payment Method</option>
                <option value="orange_money">Orange Money</option>
                <option value="bank_transfer">Bank Transfer</option>
              </select>
              {withdrawalFormData.paymentMethod === 'orange_money' && (
                <input
                  type="text"
                  placeholder="Mobile Money Number"
                  value={withdrawalFormData.mobileMoneyNumber}
                  onChange={(e) => setWithdrawalFormData({ ...withdrawalFormData, mobileMoneyNumber: e.target.value })}
                  className="mb-2 p-2 border rounded"
                  required
                />
              )}
              {withdrawalFormData.paymentMethod === 'bank_transfer' && (
                <>
                  <input
                    type="text"
                    placeholder="Bank Name"
                    value={withdrawalFormData.bankName}
                    onChange={(e) => setWithdrawalFormData({ ...withdrawalFormData, bankName: e.target.value })}
                    className="mb-2 p-2 border rounded"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Account Name"
                    value={withdrawalFormData.accountName}
                    onChange={(e) => setWithdrawalFormData({ ...withdrawalFormData, accountName: e.target.value })}
                    className="mb-2 p-2 border rounded"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Account Number"
                    value={withdrawalFormData.accountNumber}
                    onChange={(e) => setWithdrawalFormData({ ...withdrawalFormData, accountNumber: e.target.value })}
                    className="mb-2 p-2 border rounded"
                    required
                  />
                </>
              )}
              <button type="submit" className="bg-green-300 hover:bg-green-500 border border-green-500 text-white font-semibold py-2 px-4 rounded">Withdraw</button>
              <button onClick={() => setShowWithdrawalPopup(false)} className="ml-2 text-red-600 mt-2">Cancel</button>
            </form>
          </div>
        </div>
      )}

      {/* Button to open withdrawal popup */}
      <button onClick={() => setShowWithdrawalPopup(true)}
        className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br
        focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50
        dark:shadow-sm dark:shadow-green-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
      >
        Request Withdraw
      </button>

      {/* Withdrawal History Table */}
      <table className="w-full mt-10 max-w-7xl bg-white text-xs font-Poppins">
        <thead className='bg-gray-100'>
          <tr>
            <th className='px-2 py-4'>Date</th>
            <th className='px-2 py-4'>Time</th>
            <th className='px-2 py-4'>Amount Withdrawn</th>
            <th className='px-2 py-4'>Available Balance</th>
            <th className='px-2 py-4'>Withdrawal Method</th>
          </tr>
        </thead>
        <tbody>
          {walletData.map((transaction, index) => (
            <tr key={transaction.id} className={index % 2 === 0 ? 'bg-blue-50' : 'bg-gray-50'}>
              <td className='border px-2 py-3'>{transaction.date}</td>
              <td className='border px-2 py-3'>{transaction.time}</td>
              <td className='border px-2 py-3'>{transaction.amount}</td>
              <td className='border px-2 py-3'>{transaction.balance}</td>
              <td className='border px-2 py-3'>{transaction.method}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Wallet;
