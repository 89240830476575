import React, { useState } from 'react';
import { Toaster, toast } from 'sonner';
import { api } from '../../../utils/Api';

const PostponeForm = ({
  isOpen,
  onClose,
  appointmentId, // Get the appointmentId from props
  initialDate,
  onSubmit,
  feedback,
}) => {
  const [newDate, setNewDate] = useState(initialDate);
  const [newTime, setNewTime] = useState('');
  const [reason, setReason] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.patch(`/doctors/appointments/${appointmentId}/postpone`, {
        reason,
        postponedDate: newDate,
        postponedTime: newTime,
      });
      if (response.status === 200) {
        toast.success('Appointment postponed successfully.');
        feedback('Appointment postponed successfully.');
        onClose(); // Close the form on success
      }
    } catch (error) {
      const errorMessage = error.response?.data?.errors?.[0]?.msg || 'An error occurred while postponing the appointment.';
      toast.error(errorMessage);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed lg:pl-72 inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-md shadow-lg w-full max-w-md">
        <h3 className="text-xl font-semibold mb-4">Postpone Appointment</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Reason:</label>
            <textarea
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter the reason for postponing"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">New Date:</label>
            <input
              type="date"
              value={newDate}
              onChange={(e) => setNewDate(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">New Time:</label>
            <input
              type="time"
              value={newTime}
              onChange={(e) => setNewTime(e.target.value)}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="flex justify-between">
            <button
              type="submit"
              className="px-6 py-2 bg-green-500 text-white font-semibold rounded hover:bg-green-600 transition"
            >
              Postpone
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-2 bg-gray-300 text-gray-700 font-semibold rounded hover:bg-gray-400 transition"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostponeForm;
