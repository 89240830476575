import React, { useState, useEffect } from 'react';
import { Toaster } from 'sonner'; // For notifications
import { api } from '../../../utils/Api';
import AppointmentsTable from './AppointmentsTable'; // Importing the AppointmentsTable component
import ActionModal from './ActionModal'; // Importing the ActionModal component

import Pagination from './Pagination';

const PostponedAppointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Fetch postponed appointments
  const fetchPostponedAppointments = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await api.get(`/doctors/appointments?page=1&limit=20`);
      const postponedAppointments = response.data.data.filter(
        (appointment) => appointment.appointmentStatus === 'postponed'
      );
      setAppointments(postponedAppointments);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      console.error('Error fetching postponed appointments:', err);
      setError('Failed to fetch postponed appointments. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  // Open action modal
  const handleAction = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    setIsActionModalVisible(true);
  };

  // Close action modal
  const closeActionModal = () => {
    setIsActionModalVisible(false);
    setSelectedAppointmentId(null);
  };

  // Dummy meeting starter
  const startMeeting = (appointmentId) => {
    console.log(`Starting meeting for Appointment ID: ${appointmentId}`);
    // Add logic to start meeting if necessary
  };

  // Fetch appointments when component mounts
  useEffect(() => {
    fetchPostponedAppointments();
  }, []);

  return (
    <div className="p-6 space-y-6">
      {/* Render the AppointmentsTable */}
      <AppointmentsTable
        appointments={appointments}
        loading={loading}
        error={error}
        handleAction={handleAction}
        startMeeting={startMeeting}
      />

      {/* Render ActionModal */}
      {isActionModalVisible && selectedAppointmentId && (
        <ActionModal
          isVisible={isActionModalVisible}
          onClose={closeActionModal}
          selectedAppointmentId={selectedAppointmentId}
          appointments={appointments}
        />
      )}

        {/* Pagination */}
        <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageClick={handlePageClick}
      />

      {/* Toast Notifications */}
      <Toaster richColors />
    </div>
  );
};

export default PostponedAppointments;
