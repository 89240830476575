import React, { useState } from 'react';
import DoctorNavBar from '../../components/DoctorNavBar';
import Appointments_for_doctor from '../../components/doctorDashboard/apointment/Appointments';
import PendingAppointments from '../../components/doctorDashboard/apointment/PendingAppointments';
import ApprovedAppointments from '../../components/doctorDashboard/apointment//ApprovedAppointments';
import PostponedAppointments from '../../components/doctorDashboard/apointment/PostponedAppointments';
import Lottie from 'lottie-react';
import appointment from'../../lottiefiles/appointment.json'
// Import other components for approved and postponed appointments

const Appointments = () => {
  // State to manage which appointments to show
  const [activeTab, setActiveTab] = useState('all');

  // Function to render the component based on activeTab state
  const renderComponent = () => {
    switch (activeTab) {
      case 'all':
        return <Appointments_for_doctor />;
      case 'pending':
        return <PendingAppointments />;
      case 'approved':
        return <ApprovedAppointments />;
      case 'postponed':
        return <PostponedAppointments />;
      default:
        return <Appointments_for_doctor />;
    }
  };

  return (
    <div>
        <DoctorNavBar/>


        <div className='lg:pl-72 flex justify-center items-center flex-row w-full lg:px-0 px-4 bg-blue-100 pt-16'>
          <div className='lg:w-1/2 flex sm:mt-0 lg:mt-0 mt-16 sm:flex-row-reverse flex-col-reverse justify-center items-center max-w-7xl'>
            <div className='sm:w-1/2 flex justify-center items-center'>
              <Lottie className='w-full' animationData={appointment} loop={true} />
            </div>
            <div className='flex flex-col text-gray-600 items-center sm:w-full px-4 sm:px-0'>
              <h1 className='text-xl  text-center font-bold font-Poppins'>Patient appointment history</h1>
              <p className='text-center font-Poppins'>Approve your appointments, set a your own time by postponed. setup meetings and meet ur patient and prescribe  medical treatments.</p>
            </div>
          </div>
        </div>

        <div className='lg:pl-72 mt-14 mb-10 lg:px-10 px-3 flex justify-center items-center sm:overflow-x-hidden overflow-x-scroll'>
          <div className="inline-flex rounded-md shadow-sm " role="group">
            {/* Update setActiveTab on button click and apply active styling */}
            <button
              type="button"
              className={`px-4 py-2 text-xs font-medium ${activeTab === 'all' ? 'text-blue-700 bg-gray-100' : 'text-gray-900 bg-white'} border border-gray-200 rounded-s-lg focus:z-10 focus:ring-2 focus:ring-blue-700`}
              onClick={() => setActiveTab('all')}
            >
              All appointments
            </button>
            <button
              type="button"
              className={`px-4 py-2 text-xs font-medium ${activeTab === 'pending' ? 'text-blue-700 bg-gray-100' : 'text-gray-900 bg-white'} border-t border-b border-gray-200 focus:z-10 focus:ring-2 focus:ring-blue-700`}
              onClick={() => setActiveTab('pending')}
            >
              Pending appointments
            </button>
            <button
              type="button"
              className={`px-4 py-2 text-xs font-medium ${activeTab === 'approved' ? 'text-blue-700 bg-gray-100' : 'text-gray-900 bg-white'} border-t border-b border-gray-200 focus:z-10 focus:ring-2 focus:ring-blue-700`}
              onClick={() => setActiveTab('approved')}
            >
              Approved appointments
            </button>
            <button
              type="button"
              className={`px-4 py-2 text-xs font-medium ${activeTab === 'postponed' ? 'text-blue-700 bg-gray-100' : 'text-gray-900 bg-white'} border border-gray-200 rounded-e-lg focus:z-10 focus:ring-2 focus:ring-blue-700`}
              onClick={() => setActiveTab('postponed')}
            >
              Postponed appointments
            </button>
          </div>
        </div>

        <div className='lg:pl-72 mt-6 mb-10 lg:px-10 px-3 flex justify-center items-center'>
          {/* Render the component based on the current active tab */}
          {renderComponent()}
        </div>
    </div>
  );
};

export default Appointments;
