import React, { useState, useEffect } from 'react';
import AppointmentsTable from './AppointmentsTable';
import Pagination from './Pagination';
import ActionModal from './ActionModal';
import { api } from '../../../utils/Api';

const Appointments = ({
  handleAction,
  startMeeting,
  actionFeedback
}) => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [actionFeedbackMessage, setActionFeedbackMessage] = useState('');

  // Fetch appointments
  useEffect(() => {
    const fetchAppointments = async () => {
      setLoading(true);
      try {
        const response = await api.get('/doctors/appointments', {
          params: { page: currentPage, limit: 10 }
        });
        setAppointments(response.data.data);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        setError('Failed to load appointments');
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [currentPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleSelectAppointment = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    setIsActionModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsActionModalVisible(false);
  };

  return (
    <div className="w-full flex flex-col">
      <AppointmentsTable
        appointments={appointments}
        handleAction={handleSelectAppointment}
        startMeeting={startMeeting}
        loading={loading}
        error={error}
      />

      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageClick={handlePageClick}
      />

      {/* Action Modal */}
      {isActionModalVisible && (
        <ActionModal
          isVisible={isActionModalVisible}
          onClose={handleCloseModal}
          selectedAppointmentId={selectedAppointmentId}
          appointments={appointments}
          setActionFeedback={setActionFeedbackMessage}
        />
      )}

    </div>
  );
};

export default Appointments;
