import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import user_profile_lottie from '../../lottiefiles/profile_picture.json';
import { api } from '../../utils/Api';
import { Link, useNavigate } from 'react-router-dom';

const UploadProfilePictureSchema = Yup.object().shape({
  profilePicture: Yup.mixed()
    .required("A profile picture is required")
    .test("fileSize", "The file is too large", value => value && value.size <= 1024 * 1024) // Validate file size (1MB)
    .test("fileType", "Unsupported file format", value => value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)), // Validate file type
});

const UploadProfilePicture = () => {
  const [imagePreview, setImagePreview] = useState('https://images.pexels.com/photos/2690323/pexels-photo-2690323.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500');
  const [uploadError, setUploadError] = useState(''); // State to store the upload error message
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event, setFieldValue, setFieldTouched) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue("profilePicture", file);
      setImagePreview(URL.createObjectURL(file));
    }
    setFieldTouched("profilePicture", true, false);
  };

    const navigate = useNavigate();
  return (
    <Formik
      initialValues={{ profilePicture: null }}
      validationSchema={UploadProfilePictureSchema}
      onSubmit={(values, { setSubmitting }) => {
        const formData = new FormData();
        setIsLoading(true);
        formData.append('profilepic', values.profilePicture);
        api.patch('/patients/profile', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            setSubmitting(false);
            navigate('/userDashboard');
          }).catch(error => {
            const errorMessage = error.response?.data?.message || "An error occurred during upload.";
            setUploadError(errorMessage); // Set the upload error message

            // Clear the error message after 3 seconds
            setTimeout(() => {
              setUploadError('');
            }, 3000);

            setSubmitting(false);
          });
          setIsLoading(false);
      }}
    >
      {({ setFieldValue, setFieldTouched, errors, touched }) => (
        <div className="w-screen sm:h-screen min-h-screen flex justify-center flex-col items-center bg-gray-100 px-3">
          <h2 className='mb-5 mt-5 text-xl text-blue-500 font-Poppins font-bold'>Upload a profile image</h2>
          <p className="text-gray-500 text-center font-Poppins mb-2">Select a profile by clicking on the profile icon and then upload</p>
          <div className='flex flex-col sm:flex-row justify-center items-center max-w-7xl'>
            {/* <div className='hidden lg:block'>
              <Lottie animationData={user_profile_lottie} width={300} height={300} loop={true} />
            </div> */}
            <Form className="mx-auto w-64 text-center">
              <div className="relative w-64">
                <img className="w-64 h-64 rounded-full absolute" src={imagePreview} alt="Profile" />
                <label className="w-64 h-64 group hover:bg-gray-200 opacity-60 rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
                  <img className="w-12" src="https://www.svgrepo.com/show/33565/upload.svg" alt="Upload" />
                  <input type="file" name="profilePicture" accept="image/*" onChange={(event) => handleFileChange(event, setFieldValue, setFieldTouched)} className="hidden" />
                </label>
              </div>
              <button type="submit"
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
              rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
              focus:ring-gray-200 w-full mt-72 font-Poppins"
              disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Upload'}
              </button>
              {touched.profilePicture && errors.profilePicture && <div className="text-red-500 mt-2">{errors.profilePicture}</div>}
              {uploadError && <div className="text-red-500 mt-2">{uploadError}</div>}
              <Link to="/userDashboard" className="text-blue-500 font-Poppins">Skip for now</Link>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default UploadProfilePicture;
