import React, { useState } from 'react';
import AppointmentDetails from './AppointmentDetails'; // Import AppointmentDetails component
import Prescriptions from './Prescriptions'; // Import Prescriptions component
import FollowUp from './FollowUp'; // Import FollowUp component

const ActionModal = ({ isVisible, onClose, selectedAppointmentId, appointments }) => {
  const [activeTab, setActiveTab] = useState('details'); // State to manage the active tab

  // Dynamically render components based on the selected tab
  const renderComponent = () => {
    switch (activeTab) {
      case 'details':
        return (
          <AppointmentDetails
            selectedAppointmentId={selectedAppointmentId}
            appointments={appointments}
          />
        );
      case 'prescriptions':
        return (
          <Prescriptions
            selectedAppointmentId={selectedAppointmentId}
            appointments={appointments}
          />
        );
      case 'follow_ups':
        return (
          <FollowUp
            selectedAppointmentId={selectedAppointmentId}
            appointments={appointments}
          />
        );
      default:
        return (
          <AppointmentDetails
            selectedAppointmentId={selectedAppointmentId}
            appointments={appointments}
          />
        );
    }
  };

  return (
    <div
      className={`fixed lg:pl-72 inset-0 bg-gray-600 bg-opacity-30 overflow-y-auto h-full w-full flex justify-center px-4 items-center ${
        !isVisible && 'hidden'
      }`}
    >
      <div className="relative top-20 lg:mx-auto w-full lg:w-[60rem] p-5 border shadow-lg rounded-md bg-white">
        {/* Close Button */}
        <div className="w-full text-end">
          <button
            onClick={onClose}
            className="text-red-600 font-extrabold bg-red-200 py-2 px-3 rounded-full"
          >
            X
          </button>
        </div>

        {/* Tab Navigation */}
        <div
          className="w-full mt-4 mb-6 lg:px-10 px-3 flex justify-center items-center sm:overflow-x-hidden overflow-x-scroll"
          role="group"
        >
          <div className="inline-flex rounded-md shadow-sm w-full" role="group">
            <button
              onClick={() => setActiveTab('details')}
              className={`px-4 py-2 text-xs font-medium ${
                activeTab === 'details'
                  ? 'text-blue-700 bg-gray-100'
                  : 'text-gray-900 bg-white'
              } border border-gray-200 rounded-s-lg`}
            >
              Appointment Details
            </button>
            <button
              onClick={() => setActiveTab('prescriptions')}
              className={`px-4 py-2 text-xs font-medium ${
                activeTab === 'prescriptions'
                  ? 'text-blue-700 bg-gray-100'
                  : 'text-gray-900 bg-white'
              } border-t border-b border-gray-200`}
            >
              Prescriptions
            </button>
            <button
              onClick={() => setActiveTab('follow_ups')}
              className={`px-4 py-2 text-xs font-medium ${
                activeTab === 'follow_ups'
                  ? 'text-blue-700 bg-gray-100'
                  : 'text-gray-900 bg-white'
              } border border-gray-200 rounded-e-lg`}
            >
              Follow Ups
            </button>
          </div>
        </div>

        {/* Render the Active Tab's Component */}
        {renderComponent()}
      </div>
    </div>
  );
};

export default ActionModal;
