import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { submitUserDetail } from '../../redux/actions';
import Lottie from 'lottie-react';
import { Navigate, useNavigate } from 'react-router-dom';
import user_detail_form_lottie from '../../lottiefiles/user_detail_form.json'

// Define Yup validation schema
const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('First Name is required'),
  middlename: Yup.string(),
  lastname: Yup.string().required('Last Name is required'),
  gender: Yup.string().required('Gender is required'),
  dateOfBirth: Yup.date().required('Date of Birth is required'),
});

const UserDetailForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    try {
      await dispatch(submitUserDetail(values)); // Attempt to submit form data
      setSuccessMessage('User details submitted successfully.'); // Display success message
      resetForm(); // Reset the form fields
      navigate('/userMedicalDetailsForm'); // Navigate on success
    } catch (error) {
      console.log("ERROR FROM COMPONENT", error)
      // Determine the error type and display an appropriate message
      if (error.response && error.response.status === 400) {
        setErrorMessage('Bad Request: Please check your input data.'); // Display message for bad request
      } else {
        setErrorMessage('An error occurred while submitting user details.'); // Display message for other errors
      }
      setSuccessMessage(''); // Ensure success message is not displayed
      // Do not navigate away, allowing the user to correct and resubmit
    }
    setIsLoading(false);
  };

  useEffect(() => {
    let messageTimeout;
    if (successMessage) {
      messageTimeout = setTimeout(() => {
        setSuccessMessage('');
      }, 4000);
    }

    if (errorMessage) {
      messageTimeout = setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }

    return () => clearTimeout(messageTimeout);
  }, [successMessage, errorMessage]);

  return (
    <Formik
      initialValues={{
        firstname: '',
        middlename: '',
        lastname: '',
        gender: '',
        dateOfBirth: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div
        className="w-screen sm:h-screen flex justify-center flex-col items-center bg-gray-100 px-3 min-h-screen"
      >

          <h2 className='mb-5 mt-5 text-xl text-blue-500 font-Poppins font-bold'>User detail form </h2>
            <p className="text-gray-500 text-center font-Poppins">Enter you details below and submit</p>
          <div className='flex flex-col sm:flex-row justify-center items-center w-full max-w-7xl'>
            <Lottie animationData={user_detail_form_lottie} width={300} height={300} loop={true} className='hidden sm:block' />

            <div className="flex flex-col gap-4 bg-white px-6 rounded-lg shadow py-14 w-full sm:w-2/3 max-w-5x">

              {successMessage && (
                <div className="bg-green-200 col-span-2 p-2 text-green-700 mb-4 rounded">
                  {successMessage}
                </div>
              )}
              {errorMessage && (
                <div className="bg-red-200 col-span-2 p-2 text-red-700 mb-4 rounded">
                  {errorMessage}
                </div>
              )}

              <div className='flex flex-col'>
                <label htmlFor="firstname">First Name</label>
                <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text" name="firstname" id="firstname" />
                <ErrorMessage name="firstname" component="div" className="text-red-500" />
              </div>

              <div className='flex flex-col'>
                <label htmlFor="middlename">Middle Name</label>
                <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text" name="middlename" id="middlename" />
                <ErrorMessage name="middlename" component="div" className="text-red-500" />
              </div>

              <div className='flex flex-col'>
                <label htmlFor="lastname">Last Name</label>
                <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text" name="lastname" id="lastname" />
                <ErrorMessage name="lastname" component="div" className="text-red-500" />
              </div>

              <div className='flex flex-col'>
                <label htmlFor="gender">Gender</label>
                <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  as="select" name="gender" id="gender">
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Field>
                <ErrorMessage name="gender" component="div" className="text-red-500" />
              </div>

              <div className='flex flex-col'>
                <label htmlFor="dob">Date of Birth</label>
                <Field className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="date" name="dateOfBirth" id="dateOfBirth" />
                <ErrorMessage name="dateOfBirth" component="div" className="text-red-500" />
              </div>

              {/* Submit button */}
              <button type="submit"
              disabled={isLoading}
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                rounded-lg border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                focus:ring-gray-200 w-full mt-5 font-Poppins"
              >
                {isLoading ? 'Loading...' : 'Submit'}
              </button>
            </div>

          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default UserDetailForm;
