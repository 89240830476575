import React, { useState } from 'react';
import { Toaster, toast } from 'sonner'; // Import Sonner for toasts
import { api } from '../../../utils/Api';
import PostponeForm from './PostponeForm'; // Import the PostponeForm

const AppointmentDetails = ({
  selectedAppointmentId,
  appointments,
  setIsActionModalVisible,
  setActionFeedback,
  handlePostponeAction, // Pass the action handler here
}) => {
  const [isPostponeFormVisible, setIsPostponeFormVisible] = useState(false); // Local state for form visibility

  if (!selectedAppointmentId) return null;

  const selectedAppointment = appointments.find(appointment => appointment.appointmentId === selectedAppointmentId);
  if (!selectedAppointment) return null;

  // Approve Appointment
  const handleApprove = async () => {
    if (selectedAppointmentId) {
      try {
        const response = await api.patch(`/doctors/appointments/${selectedAppointmentId}/approve`);
        if (response.status === 200) {
          toast.success('Appointment approved successfully.');
          setTimeout(() => setIsActionModalVisible(false), 3000); // Close the modal after success
        }
      } catch (error) {
        if (error.response) {
          const errorMessage = error.response.data.errors?.[0]?.msg || 'An error occurred while approving the appointment.';
          toast.error(errorMessage);
        } else {
          toast.error('An error occurred while approving the appointment.');
        }
      }
    }
  };

  // Postpone Appointment
  const handlePostpone = () => {
    setIsPostponeFormVisible(true); // Show the PostponeForm when clicked
  };


  return (
    <div className="p-4 bg-white rounded-lg">
      <h3 className="text-2xl font-semibold mb-4">Appointment Details</h3>

    <div className='flex justify-between px-4'>
      {/* Appointment Info */}
      <div className="mb-4">
        <p><strong>ID:</strong> {selectedAppointment.appointmentId}</p>
        <p><strong>Patient Name:</strong> {selectedAppointment.patientNameOnPrescription}</p>
        <p><strong>Patient Mobile Number:</strong> {selectedAppointment.patientMobileNumber}</p>
        <p><strong>Symptoms:</strong> {selectedAppointment.patientSymptoms}</p>
        <p><strong>Consultation Fees:</strong> {selectedAppointment.consultationFees}</p>
        <p><strong>Appointment Date:</strong> {new Date(selectedAppointment.appointmentDate).toLocaleDateString()}</p>
        <p><strong>Appointment Time:</strong> {selectedAppointment.appointmentTime}</p>
        <p><strong>Appointment Type:</strong> {selectedAppointment.appointmentType}</p>
      </div>

      {/* Additional Info */}
      <div className="mb-4">
        <h4 className="font-semibold text-lg">Additional Information</h4>
        <p><strong>Appointment Status:</strong> {selectedAppointment.appointmentStatus}</p>
        <p><strong>Doctor Assigned:</strong> {selectedAppointment.doctorName}</p>
        <p><strong>Payment Method:</strong> {selectedAppointment.paymentMethod}</p>
        <p><strong>Payment Status:</strong> {selectedAppointment.paymentStatus}</p>
        <p><strong>Created At:</strong> {new Date(selectedAppointment.createdAt).toLocaleDateString()}</p>
      </div>
    </div>


      {/* Action Buttons - Only show if status is "Pending" */}
      {selectedAppointment.appointmentStatus && selectedAppointment.appointmentStatus.toLowerCase() === 'pending' && (
        <div className="flex justify-between space-x-4">
          <button
            className="px-6 py-2 bg-green-500 text-white font-semibold rounded hover:bg-green-600 transition"
            onClick={handleApprove}
          >
            Approve
          </button>

          <button
            className="px-6 py-2 bg-yellow-500 text-white font-semibold rounded hover:bg-yellow-600 transition"
            onClick={handlePostpone}
          >
            Postpone
          </button>
        </div>
      )}

      {/* Toast Notifications */}
      <Toaster richColors />

      {/* Postpone Form */}
      {isPostponeFormVisible && (
        <PostponeForm
          isOpen={isPostponeFormVisible}
          onClose={() => setIsPostponeFormVisible(false)} // Close form
          onSubmit={handlePostponeAction} // Submit postpone action
          initialDate={new Date().toISOString().slice(0, 10)} // Default current date for the new appointment date
          appointmentId={selectedAppointment.appointmentId} // Pass the selected appointment ID to the form
          feedback={setActionFeedback} // Pass feedback function if needed
        />
      )}
    </div>
  );
};

export default AppointmentDetails;
