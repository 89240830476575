import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { signupUser } from '../../redux/actions'; // Adjust the path as needed
import { Link, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import signup_lottie from '../../lottiefiles/user_signup.json';

const SignupSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required('Mobile number is required'),
    //.matches(/^\+232\d{8}$/, "Mobile number must start with +232 followed by eight digits"),
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])(?!.*\s).{8,}$/,
      'Password must contain at least one uppercase letter, one number, one special character (@#$%^&+=!), and must be 8 digits long'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
  agreement: Yup.boolean()
    .oneOf([true], 'You must accept the terms and conditions')
    .required('You must accept the terms and conditions'),
  referralCode: Yup.string().notRequired(),
});

const SignupForm = () => {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSuccess = (message) => {
    setSuccessMessage(message || 'Signup successful');
    setTimeout(() => setSuccessMessage(''), 4000);
    setErrorMessage('');
    navigate('/otpVerification');
  };

  // TODU: Handle error message
  const handleError = (errors) => {
    setErrorMessage(Array.isArray(errors) ? errors : [errors]);
    setTimeout(() => setErrorMessage([]), 5000);
  };

  return (
    <Formik
      initialValues={{ mobileNumber: '', password: '', confirmPassword: '', referralCode: "", agreement: false }}
      validationSchema={SignupSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setIsLoading(true);
        const country_code = '+232';
        const mobileNum = values.mobileNumber.slice(-8);
        values.mobileNumber = country_code + mobileNum;
        const { agreement, ...formData } = values; // Exclude agreement from form data
        dispatch(signupUser(formData))
          .then((response) => {
            if (response && response.data) {
              if (response.data.error) {
                handleError(response.data.error);
              } else {
                handleSuccess(response.data.message || 'Signup successful');
                resetForm();
              }
            } else {
              handleSuccess('Signup successful');
              resetForm();
            }
          })
          .catch((errors) => {
            handleError(errors.message || 'An error occurred during signup.');
          })
          .finally(() => {
            setSubmitting(false);
            setIsLoading(false);
          });
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className='w-screen sm:h-screen flex justify-center flex-col items-center bg-gray-100 px-3'>
          <div className='flex flex-col sm:flex-row justify-center items-center max-w-7xl'>

            <div className='hidden sm:flex flex-col'>
              <h2 className='mb-1 mt-1 text-xl text-blue-500 font-Poppins font-bold'>Create an account with kenecare </h2>
            <Lottie animationData={signup_lottie} width={300} height={300} loop={true} />
            </div>

            <div className="bg-white sm:w-1/2 w-full shadow-sm rounded-lg px-4 pt-6 pb-8 mb-4">
              {successMessage && (
                <div className="bg-green-200 p-2 text-green-700 mb-4 rounded">
                  {successMessage}
                </div>
              )}
              {errorMessage && (
                <div className="bg-red-200 p-2 text-red-700 mb-4 rounded">
                  {errorMessage}
                </div>
              )}
              <h2 className='mb-2 text-lg text-gray-600 font-Poppins'>Patent Signup form </h2>
              <h2 className='mb-5 text-lg text-gray-600'>Already have an account? <span className='text-blue-500'> <Link to={'/'}> Login </Link></span>. </h2>
              <div className="mb-4">
                <label htmlFor="mobileNumber" className="block text-gray-600 font-Poppins text-sm font-bold mb-2">Mobile Number</label>
                <Field name="mobileNumber" type="text" className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                <ErrorMessage name="mobileNumber" component="div" className="text-red-500 text-xs italic" />
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block text-gray-600 font-Poppins text-sm font-bold mb-2">Password</label>
                <Field name="password" type="password" className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                <p className='text-gray-500 text-xs max-w-[22rem]'>password must be 8 digit long & contain letter, uppercase, special character & number</p>
                <ErrorMessage name="password" component="div" className="text-red-500 text-xs italic" />
              </div>
              <div className="mb-6">
                <label htmlFor="confirmPassword" className="block text-gray-600 font-Poppins text-sm font-bold mb-2">Confirm Password</label>
                <Field name="confirmPassword" type="password" className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                <ErrorMessage name="confirmPassword" component="div" className="text-red-500 text-xs italic" />
              </div>

              {/* Referral Code */}
              <div className="mb-6">
                <label htmlFor="referralCode" className="block text-gray-600 font-Poppins text-sm font-bold mb-2">
                  Referral Code (Optional)
                </label>
                <Field
                  name="referralCode"
                  type="text"
                  className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                />
              </div>

              <div className="mb-6">
                <label htmlFor="agreement" className="block text-gray-600 font-Poppins text-sm mb-2">
                  <Field type="checkbox" name="agreement" checked={values.agreement} onChange={() => setFieldValue("agreement", !values.agreement)} className="mr-2 leading-tight" />
                    I agree to the <span> <a href='https://kenecare.com/terms' target='_blank' rel="noreferrer"
                    className='text-blue-500 text-sm'>terms and conditions</a></span>
                </label>
                <ErrorMessage name="agreement" component="div" className="text-red-500 text-xs italic" />
              </div>
              <h2 className='mb-5 text-lg text-gray-600 font-Poppins'>Doctor sign up <span className='text-blue-500'> <Link to={'/doctorSignUp'}> here</Link></span>. </h2>
              <div className="flex items-center justify-between">
                <button type="submit"
                  className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                  rounded-lg border border-blue-300 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                  focus:ring-gray-200 w-full mt-5 font-Poppins"
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Sign Up'}
                </button>
              </div>
            </div>

          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
