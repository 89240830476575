import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { loginUser } from '../../redux/actions';
import { Link, useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import loggin_lottie from '../../lottiefiles/login.json'
import logo from '../../images/logo.png'
import ForgottenPassword from './ForgottenPassword';

const LoginSchema = Yup.object().shape({
  mobileNumber: Yup.string()
    .required('Phone number is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
});

const navigate = useNavigate;

const UserLogin = () => {
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSuccess = (message, userType) => {
    setSuccessMessage(message || 'Login successful');

    setTimeout(() => {
        setSuccessMessage('');
        window.location.reload();
    }, 2000);

    setErrorMessage('');

    if (userType === '2') {
        navigate('/doctorDetailForm');
    } else if (userType === '1') {
        navigate('/userDetailForm');
    } else {
    }
  };

  const handleError = (error) => {
    const { message, errors } = error.response.data;
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        setErrorMessage(error.msg);
      });
    }
    else if (message) {
      setErrorMessage(message);
    } else {
      setErrorMessage('An error occurred during login.');
    }
    setTimeout(() => setErrorMessage(''), 5000);
    setSuccessMessage('');
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {showForgotPassword && <ForgottenPassword setShowForgotPassword={setShowForgotPassword} />}
      <Formik
        initialValues={{ mobileNumber: '', password: '' }}
        validationSchema={LoginSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          const country_code = '+232';
          const mobileNum = values.mobileNumber.slice(-8);
          values.mobileNumber = country_code + mobileNum;
          try {
            await dispatch(loginUser(values)).catch((error) => {
              throw error;
            }).finally(() => {
              setSubmitting(false);
              handleSuccess()
            })
          } catch (error) {
            handleError(error);
          }
          setIsLoading(false);
        }
        }
      >
        {() => (
          <Form className='w-screen sm:h-screen flex justify-center flex-col items-center bg-gray-100 px-3 -z-0 py-6'
          style={{ backgroundImage: 'linear-gradient(to top, #accbee 0%, #e7f0fd 100%)' }}>
            <img src={logo} alt='logo' className='w-[10rem]' />
            <h2 className='mb-5 mt-2 text-lg text-blue-500 font-Poppins font-bold'>Beteh welbodi na u han</h2>
            <div className='flex flex-col sm:flex-row justify-center items-center max-w-7xl p-4 rounded-md bg-white shadow-md'>
                <Lottie animationData={loggin_lottie} width={300} height={300} loop={true} />
              <div className="bg-white sm:w-1/2 w-full rounded-lg px-4 pt-6 pb-8 mb-4">
                <h2 className='mb-5 text-md text-gray-600 font-Poppins'>Login to your dashboard </h2>

                {successMessage && (
                  <div className="bg-green-200 p-2 text-green-700 text-xs mb-4 rounded">
                    {successMessage}
                  </div>
                )}

                {errorMessage && (
                  <div className="bg-red-200 p-2 text-red-700 text-xs mb-4 rounded">
                    {errorMessage}
                  </div>
                )}

                <div className="mb-4">
                  <label className="block text-gray-600 text-sm font-bold mb-2 font-poppins" htmlFor="mobileNumber">
                    Mobile Number
                  </label>
                  <Field name="mobileNumber"
                    type="text"
                    placeholder="Enter your phone number"
                    className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700
                      leading-tight focus:outline-none focus:shadow-outline" />
                  <ErrorMessage name="mobileNumber" component="div" className="text-red-500 text-xs italic" />
                </div>



                <div className="w-full mb-3">
                  <label className="block text-gray-600 text-sm font-bold mb-2 font-poppins"
                    htmlFor="password">
                    Password
                  </label>


                  <div className=' relative w-full'>
                    <Field
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      className="appearance-none border border-blue-300 rounded w-full py-2 px-3 text-gray-700
                      leading-tight focus:outline-none focus:shadow-outline"
                      placeholder="Enter your password"
                    />
                    <button
                      type="button"
                      onClick={toggleShowPassword}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                    >
                      {showPassword ? (
                        <svg class="w-6 h-6 text-blue-300"
                          aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path d="m4 15.6 3.055-3.056A4.913 4.913 0 0 1 7 12.012a5.006 5.006 0 0 1 5-5c.178.009.356.027.532.054l1.744-1.744A8.973 8.973 0 0 0 12 5.012c-5.388 0-10 5.336-10 7A6.49 6.49 0 0 0 4 15.6Z"/>
                          <path d="m14.7 10.726 4.995-5.007A.998.998 0 0 0 18.99 4a1 1 0 0 0-.71.305l-4.995 5.007a2.98 2.98 0 0 0-.588-.21l-.035-.01a2.981 2.981 0 0 0-3.584 3.583c0 .012.008.022.01.033.05.204.12.402.211.59l-4.995 4.983a1 1 0 1 0 1.414 1.414l4.995-4.983c.189.091.386.162.59.211.011 0 .021.007.033.01a2.982 2.982 0 0 0 3.584-3.584c0-.012-.008-.023-.011-.035a3.05 3.05 0 0 0-.21-.588Z"/>
                          <path d="m19.821 8.605-2.857 2.857a4.952 4.952 0 0 1-5.514 5.514l-1.785 1.785c.767.166 1.55.25 2.335.251 6.453 0 10-5.258 10-7 0-1.166-1.637-2.874-2.179-3.407Z"/>
                        </svg>
                      ) : (
                        <svg class="w-6 h-6 text-blue-300"
                          aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                          <path fill-rule="evenodd" d="M4.998 7.78C6.729 6.345 9.198 5 12 5c2.802 0 5.27 1.345 7.002 2.78a12.713 12.713 0 0 1 2.096 2.183c.253.344.465.682.618.997.14.286.284.658.284 1.04s-.145.754-.284 1.04a6.6 6.6 0 0 1-.618.997 12.712 12.712 0 0 1-2.096 2.183C17.271 17.655 14.802 19 12 19c-2.802 0-5.27-1.345-7.002-2.78a12.712 12.712 0 0 1-2.096-2.183 6.6 6.6 0 0 1-.618-.997C2.144 12.754 2 12.382 2 12s.145-.754.284-1.04c.153-.315.365-.653.618-.997A12.714 12.714 0 0 1 4.998 7.78ZM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd"/>
                        </svg>
                      )}
                    </button>
                  </div>

                  <ErrorMessage name="password"
                    component="div"
                    className="text-red-500 text-xs italic"
                  />

                </div>

                <p className='mb-3 text-gray-500 font-Poppins text-sm'>If u don't have an account <span className='text-blue-500'>
                  <Link to={'/userSignUp'}>Sign Up</Link> </span></p>
                <div>
                  <p className='text-gray-500 font-Poppins text-sm'>Forgot your password? <span className='text-blue-500 cursor-pointer' onClick={() => setShowForgotPassword(true)}>
                    Reset Password</span></p>
                </div>
                <div className="flex items-center justify-between">
                  <button type="submit"
                    className="py-2.5 px-5  mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white
                    rounded border border-blue-300  hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4
                    focus:ring-gray-200 w-full mt-5 font-Poppins"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading...' : 'Login'}
                  </button>
                </div>
              </div>
            </div>
            <p className='text-blue-500 mt-8 text-sm tex'>© 2023 All Rights Reserved. powered by
              <span className='hover:font-semibold'> <a href='https://sl.imo-tech.com/' target='_blank' rel="web noreferrer"> IMO-Tech Solutions</a> </span>
            </p>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default UserLogin;
