import React, { useState, useEffect } from "react";
import { api } from "../../utils/Api";

const PatientSharedDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch the list of shared documents from the API
    const fetchDocuments = async () => {
      try {
        const response = await api.get("/doctors/shared-medical-docs");
        if (response.status === 200) {
          setDocuments(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching documents", error);
      }
    };

    fetchDocuments();
  }, []);

  const handleViewDocument = async (sharingId) => {
    try {
      const response = await api.get(`/doctors/shared-medical-docs/${sharingId}`);
      if (response.status === 200) {
        setSelectedDocumentUrl(response.data.data.documentUrl);
        setShowPopup(true);
        setError(null);
      } else {
        setError("Document is not available.");
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Error fetching document URL", error);
      setError("Document is not available.");
      setShowPopup(true);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Shared Medical Documents</h1>
      <table className="min-w-full bg-white border border-gray-300">
        <thead className="bg-blue-800 text-white">
          <tr>
            <th className="py-2 px-4 border-b">Date</th>
            <th className="py-2 px-4 border-b">Patient Name</th>
            <th className="py-2 px-4 border-b">Doctor Name</th>
            <th className="py-2 px-4 border-b">Document Title</th>
            <th className="py-2 px-4 border-b">Note</th>
            <th className="py-2 px-4 border-b">Document</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((document) => (
            <tr key={document.sharingId}>
              <td className="py-2 px-4 border-b">{document.createdAt}</td>
              <td className="py-2 px-4 border-b">{document.patientName}</td>
              <td className="py-2 px-4 border-b">{document.doctorName}</td>
              <td className="py-2 px-4 border-b">{document.documentTitle}</td>
              <td className="py-2 px-4 border-b">{document.note}</td>
              <td className="py-2 px-4 border-b text-center">
                <button
                  onClick={() => handleViewDocument(document.sharingId)}
                  className="text-blue-500 hover:text-blue-700">
                    <svg className="size-6 bg-blue-500 hover:bg-blue-700 text-white rounded-full p-2"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0 0 16.5 9h-1.875a1.875 1.875 0 0 1-1.875-1.875V5.25A3.75 3.75 0 0 0 9 1.5H5.625Z" />
                      <path d="M12.971 1.816A5.23 5.23 0 0 1 14.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 0 1 3.434 1.279 9.768 9.768 0 0 0-6.963-6.963Z" />
                    </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showPopup && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
    <div className="relative bg-white w-full h-full overflow-auto">
      <button
        onClick={() => {
          setShowPopup(false);
          setError(null); // Reset the error when closing the popup
        }}
        className="absolute top-4 right-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 z-50"
      >
        Close
      </button>

      {error ? (
        <div className="flex items-center justify-center h-full">
          <div className="text-red-500 font-bold text-lg">{error}</div>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <h2 className="text-2xl font-bold mb-4">View Document</h2>
          <iframe
            src={selectedDocumentUrl}
            title="Document Viewer"
            className="w-full h-full"
            frameBorder="0"
            scrolling="yes" // Enable scrolling
            style={{
              pointerEvents: "auto", // Allow scrolling
              userSelect: "none",    // Disable text selection
            }}
          ></iframe>
        </div>
      )}

      {/* Prevent right-click */}
      <style>
        {`
          iframe {
            -webkit-user-select: none;
            -webkit-touch-callout: none;
            user-select: none;
          }

          iframe::-webkit-scrollbar {
            display: none; /* Hides default scrollbars if you want custom ones */
          }
        `}
      </style>
    </div>
  </div>
)}


    </div>
  );
};

export default PatientSharedDocuments;
